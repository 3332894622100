import styled, { css } from "styled-components"

export const Heading = styled.h1`
    color:  ${p => (p.color ? p.color : p.theme.colors.white)};
    font-size: clamp(28px, 4vw, 50px);
    line-height: 1.42;
    font-weight: 400;
`

export const HeadingSpan = styled.span`
    font-size: clamp(21px, 4vw, 38px);
`

export const Subheading = styled.h2`
    font-size: clamp(21px, 4vw, 38px);
    color:  ${p => (p.color ? p.color : p.theme.colors.white)};
`

const paragraphCss = css`
    font-size: 14px;
    color:  ${p => (p.color ? p.color : p.theme.colors.white)};
    line-height: 1.42;
    font-weight: 400;
    @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
        font-size: 21px;

}
`

export const Paragraph = styled.p`
    ${paragraphCss}
`

export const Link = styled.a`
    ${paragraphCss}
    text-decoration: none;
    cursor: pointer;
    text-align: left;
`

const label = css`
    font-size: 16px;
    line-height: 1.42;
    font-weight: 400;
`

export const FormError = styled.p`
    ${label}
    color: ${p => p.theme.colors.red};
`

export const FormLabel = styled.label`
    ${label};
    color: ${p => p.theme.colors.primary};
    &.error {
        color: ${(props) => props.theme.colors.red};
    }
`

export const FormLabelSpan = styled.span`
    color: ${p => p.theme.colors.gray};
`