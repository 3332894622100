export const scrollToElement = (ref, offset) => {
    const isBrowser = typeof window !== `undefined`;
    if(isBrowser) {
        window.scrollTo({
          top:
            ref.current.getBoundingClientRect().top + window.pageYOffset + offset,
          behavior: "smooth",
        });
    }
  };
  