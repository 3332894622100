import styled, { css } from "styled-components";

export const style = css`
  width: 100%;
  font-size: 21px;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  padding: 4px;

  &:focus {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

export const HeadingWrapper = styled.div`
  margin: 32px 0 32px 0;
@media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
    margin: 96px 0 64px 0;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const StyledInput = styled.input`
  ${style}
  background-color: transparent;
  &::placeholder {
    color: ${(props) => props.theme.colors.gray};
  }
  &.error {
    border: 1px solid ${(props) => props.theme.colors.red};
  }
`;

export const StyledTextarea = styled.textarea`
  ${style}
  height: 100%;
  background-color: transparent;
  &::placeholder {
    color: ${(props) => props.theme.colors.gray};
  }
  &.error {
    border: 1px solid ${(props) => props.theme.colors.red};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: auto;
  margin-bottom: 128px;
  flex-direction: column;
  justify-content: center;
  align-items:  center;
  /* @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.tablet_small}) {
    width: 500px;
  }
  @media screen and (max-width: ${(props) =>
    props.theme.breakpoints.phone_big}) {
    width: 300px;
  } */
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: ${(props) => (props.submitted ? "none" : "flex")};
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: left;
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
    width: 520px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: flex-start;
  /* height: 100%; */
  &.h-170 {
    height: 170px;
  }
  .g-recaptcha {
    transform: scale(0.7);
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.tablet_small}) {
    width: 520px;
  }
  /* @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-bottom: 10px;
  } */
`;

export const HiddenInput = styled.div`
  visibility: none;
`;

export const ErrorContainer = styled.div`
  height: 22px;
`;

